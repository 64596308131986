import { graphql } from "gatsby"
import React from "react"
import HeaderWithIcon from "../components/headers/headerWithIcon"
import Seo from "../components/seo"
import SalesIcon from "../../static/svg/salesIcon.svg"
import SaleCardPanel from "../components/saleCardPanel"
import StoriesPanel from "../components/widgets/storiesPanel"
import ScrollBar from "../components/scrollBar"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const IndexPage = ({ data }) => {
  const AllSales = data.allPrismicSales.edges.map(edge => edge.node)
  const allStories = data.allPrismicStories.edges.map(edge => edge.node)

  const ldJsonScript = {
    "@context": "http://schema.org",
    "@type": "ItemList",
    name: "Акции и предложения | Krypton.ru",
    description: "Специальные акции и предложения от нашей компании",
    url: "https://www.krypton.ru/sales",
    itemListElement: [
      ...allStories.map(story => ({
        "@type": "Article",
        name: story.data.text.text,
        description: story.data.description,
        url: `https://krypton.ru/${story.data.link.text}`,
        image:
          story?.data?.image.localFile.childImageSharp.gatsbyImageData.images
            .fallback.src,
      })),
      ...AllSales.map(sale => ({
        "@type": "Offer",
        name: sale.data.title.text,
        description: sale.data.previewtext.text,
        url: sale.data.link.text,
        image:
          sale?.data?.previewimage.localFile.childImageSharp.gatsbyImageData
            .images.fallback.src,
        priceCurrency: "RUB",
        validThrough: sale.data.enddate,
      })),
    ],
  }

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(ldJsonScript)}
        </script>
      </Helmet>
      <Seo title="Акции и предложения" />
      <HeaderWithIcon
        icon={<SalesIcon />}
        title="Акции и предложения"
        divider={false}
        subcategory
      />
      <ScrollBar fullScreen buttonNext>
        <StoriesPanel stories={allStories} />
      </ScrollBar>
      <SaleCardPanel sales={AllSales} />
    </Layout>
  )
}

/**
 * Страница акций
 * @module src/page/sales
 * @param {Object} props - объект свойств компонента React
 * @param {Object} props.data - объект данных полученый из prismic
 */
export default IndexPage

export const query = graphql`
  query AllSales {
    allPrismicSales {
      edges {
        node {
          uid
          data {
            sales_order
            creationdate
            enddate
            startdate
            previewimage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            image_mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
            previewtext {
              text
            }
            title {
              text
            }
            salestext {
              html
            }
            link {
              text
            }
          }
        }
      }
    }
    allPrismicStories {
      edges {
        node {
          data {
            link
            tumbler_link
            text {
              text
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
